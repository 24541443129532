import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useScrollFreeze } from '@hooks';

import Close from '@svg/close.svg';

const searchVars = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const Inner = ({ setPopupOpen }) => {
  useScrollFreeze();

  return (
    <motion.div
      variants={searchVars}
      initial='closed'
      animate='open'
      exit='closed'
      className='py-40 overflow-y-scroll z-50 bg-black w-screen h-full fixed top-0 items-center justify-center flex flex-col px-gutter lg:px-0 lg:grid grid-cols-14'>
      <div className='col-start-4 col-end-12 text-white gap-y-8 md:gap-y-16 flex flex-col'>
        <h1 className='title-block w-full text-center'>Ticketing</h1>
        <div>
          <h2 className='text-xl leading-xl md:leading-4xl md:text-4xl font-semibold'>
            Single Concert
          </h2>
          <p className='text-base md:text-xl font-semibold mt-6 pt-6 border-t border-white'>
            Tickets to individual concerts can be booked now!
          </p>
        </div>
        <div>
          <h2 className='text-xl leading-xl md:leading-4xl md:text-4xl font-semibold'>
            Series Packages
          </h2>
          <p className='text-base md:text-xl font-semibold mt-6 pt-6 border-t border-white'>
            Book two, three or five concerts and enjoy exclusive subscriber
            discounts.
          </p>
        </div>
      </div>

      <button
        onClick={() => setPopupOpen(false)}
        className='absolute top-0 lg:top-5 right-gutter lg:right-halfGutter focus:outline-none'>
        <Close className='w-10 lg:w-16' />
      </button>
    </motion.div>
  );
};

export const TicketingPopup = ({ popupOpen, setPopupOpen }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {popupOpen && <Inner key={popupOpen} setPopupOpen={setPopupOpen} />}
    </AnimatePresence>
  );
};
